import debounce from 'lodash/debounce';
import { appConfig } from '@/app.config';
import { DateTime } from 'luxon';
import { DATEPICKER_FORMAT } from '@/helpers/date.helper';

export const debounceFilter = (filterFunc: (...args: any) => any) => debounce(filterFunc, Number(appConfig.debounceFilter));

export const setEmptyToNull = (filter: Object) => {
  for (const [attrName, value] of Object.entries(filter)) {
    if (value === '') {
      filter[attrName] = null as any;
    }
  }

  return filter;
};

/**
 * Remove attributes if value equals null|undefined|empty
 * @param filter
 */
export const removeEmptyValues = (filter: Object | any) => {
  for (const [attrName, value] of Object.entries(filter)) {
    if (value === undefined || value === null || value === '') {
      delete filter[attrName];
    }
  }

  return filter;
};

export const prepareFilterDate = (name: string, value: string, inMs = false) => {
  const toFunc = inMs ? 'toMillis' : 'toSeconds';
  return value ? { [name]: DateTime.fromFormat(value, DATEPICKER_FORMAT, { zone: 'utc' })[toFunc]() } : {};
}

export const getFilterAsString = (filter: any) => {
  const prepared = removeEmptyValues(filter);
  return Object.entries(prepared).map(item => `${item[0]}=${item[1]}`).join(',');
}
