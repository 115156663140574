import { Vue, Component } from 'vue-property-decorator';
import isFunction from 'lodash/isFunction';

@Component
export default class FocusMixin extends Vue {
  lastFocused = '';

  mounted(): void {
    this.restoreFocus();
  }

  saveFocus(event) {
    this.lastFocused = event.target.id;
  }

  restoreFocus() {
    const filterName = this.$route.params.lastFocused;
    if (filterName) {
      const element: HTMLElement = this.$refs[filterName] as HTMLElement;
      if (element && isFunction(element.focus)) {
        element.focus();
      }
    }
  }

  removeFocus() {
    this.lastFocused = '';
  }
}
