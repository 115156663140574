import { Component, Mixins } from 'vue-property-decorator';
import { debounceFilter, removeEmptyValues } from '@/helpers/filter.helper';
import FocusMixin from './focus.mixin';
import { GridTablePaginationParams, IGridTableSort } from '@/components/ui/grid-table/gridTable.ts';
import { Getter } from 'vuex-class';
import { BrandType } from '@/types/api/brands/brand.type';

@Component
export default class FilterMixin extends Mixins(FocusMixin) {
  /**
   * Parameters that sent by api
   */
  filter: any = {};

  /**
   * Parameters that do not need to be sent by api
   * For example: tabIndex or other params
   */
  pageFilter: any = {};

  /**
   * Save filter in LS
   */
  saveFilter = false;

  useBrandFilter = false;

  @Getter('getBrands', { namespace: 'brands' })
  brands!: BrandType[];

  created() {
    this.applySavedFilter();
    this.debounceApplyFilter = debounceFilter(this.debounceApplyFilter);
  }

  initFilter() {
    return {};
  }

  initPageFilter() {
    return {};
  }

  applySavedFilter() {
    if (this.saveFilter) {
      const location = window.localStorage.getItem(this.$route.name!);
      if (location && !Object.keys(this.$route.query).length) {
        const locationObject = JSON.parse(location)
        const url = new URL(`${window.location.origin}${window.location.pathname}`);
        Object.entries(locationObject.query as { [key: string]: string }).forEach(([name, value]) => {
          url.searchParams.append(name, value);
        })
        window.location.replace(url.href)
      } else {
        this.applyBrandFilter();
      }
    } else {
      this.applyBrandFilter();
    }
  }

  applyBrandFilter() {
    if (this.useBrandFilter) {
      const brandId = this.brands.length === 1 ? this.brands[0].id : this.$route.query.brandId;
      if (brandId !== this.$route.query.brandId) {
        this.pageFilter.brandId = brandId;
        this.applyFilter();
      }
    }
  }

  applyFilter() {
    const location = {
      name: this.$route.name!,
      query: {
        ...removeEmptyValues({
          ...this.$route.query,
          ...this.filter,
          ...this.pageFilter
        }),
      },
      params: {
        ...(this.lastFocused ? { lastFocused: this.lastFocused } : {})
      }
    };
    const hasQuery = !!Object.keys(location.query).length;

    if (this.saveFilter && hasQuery) {
      window.localStorage.setItem(this.$route.name!, JSON.stringify(location));
    } else if (this.saveFilter && !hasQuery) {
      window.localStorage.removeItem(this.$route.name!);
    }

    this.$router.replace(location).catch(() => {});
  }

  paginationFilter(params: GridTablePaginationParams) {
    return {
      skip: params.pageLimit * (params.pageNumber - 1),
      take: params.pageLimit,
      ...this.sortFilter(params?.sort)
    }
  }

  sortFilter(params?: IGridTableSort) {
    if (!params) {
      return {};
    }

    const sortDesc = params.sortDesc ? 'Desc' : 'Asc';
    return { 'sort': `${params.sortBy}${sortDesc}` }
  }

  prepareFilter(customFilter: any = {}) {
    const filter = {};
    Object.entries({ ...this.filter, ...customFilter }).forEach(item => {
      filter[item[0]] = item[1];
    })

    return filter;
  }

  resetFilter() {
    this.filter = this.initFilter();
    this.pageFilter = this.initPageFilter();
    this.applyFilter();
  }

  debounceApplyFilter() {
    this.applyFilter();
  }

  get hasFilter() {
    return Object.values(this.filter).some(item => item);
  }
}
